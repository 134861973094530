import * as React from 'react';
import { useEffect, useState } from 'react';
// React admin
import {
  useLogin,
  useNotify,
  Form,
  TextInput,
  required,
  PasswordInput,
  SaveButton,
  useTranslate,
  useTheme,
} from 'react-admin';
// Material UI
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
// Style
import getTheme from '../styles/theme';
import styles from '../styles/pages/connection.module.css';
// Utils
import cn from '../utils/cn';
// Images
import Logo from '../assets/images/logo-small.png';

function CustomLogin() {
  const [, setTheme] = useTheme();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const login = useLogin();
  const notify = useNotify();
  const t = useTranslate();

  useEffect(() => {
    setTheme(getTheme('light'));
  }, []);

  const handleSubmit = () => {
    login({ email, password }).catch(
      () => notify(t('ra.auth.sign_in_error'), { type: 'error' }),
    );
  };

  return (
    <Form className={styles.container} onSubmit={handleSubmit}>
      <div className={cn([styles.form, 'p-xl flex align-center column'])}>
        <img
          alt="faciliciti-logo"
          src={Logo}
          className={cn([styles.logo, 'mb-m'])}
        />
        <div className="mb-l">
          <Typography
            variant="h1"
            component="p"
            className="text-center"
            fontWeight={500}
          >
            {t('ra.auth.sign_in')}
          </Typography>
        </div>
        <TextInput
          name="email"
          label={t('ra.auth.username')}
          source="email"
          validate={[required()]}
          onChange={(e) => setEmail(e.target.value)}
          fullWidth
        />
        <PasswordInput
          label={t('ra.auth.password')}
          name="password"
          source="password"
          validate={[required()]}
          onChange={(e) => setPassword(e.target.value)}
          fullWidth
        />
        <Link
          href="/#/forgot-password"
          className={cn([
            styles['forgot-password'], 'full-width flex justify-end row'])}
        >
          <Typography variant="caption" component="p">
            {t('ra.auth.forgotPassword')}
          </Typography>
        </Link>
        <SaveButton
          label={t('ra.auth.sign_in')}
          type="submit"
          icon={null}
          fullWidth
        />
      </div>
    </Form>
  );
}

export default CustomLogin;
