import * as React from 'react';
import { useEffect, useState } from 'react';
// React admin
import {
  Form,
  TextInput,
  required,
  email,
  SaveButton,
  useTranslate,
  useTheme,
  useNotify,
} from 'react-admin';
// Material UI
import Typography from '@mui/material/Typography';
// Style
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import styles from '../styles/pages/connection.module.css';
import getTheme from '../styles/theme';
// Utils
import cn from '../utils/cn';
// Images
import Logo from '../assets/images/logo-small.png';
import AuthService from '../services/AuthService';

function ForgotPassword() {
  const [, setTheme] = useTheme();
  const [emailValue, setEmail] = useState('');
  const notify = useNotify();
  const navigate = useNavigate();
  const t = useTranslate();

  // queries
  const forgotPasswordMutation = useMutation((data) => AuthService.forgotPassword(data), {
    onSuccess: () => {
      notify('forgotPassword.success', { type: 'success' });
      setTimeout(() => navigate('/#/login', { replace: true }), 4000);
    },
  });

  const handleSubmit = () => {
    forgotPasswordMutation.mutate(emailValue);
  };

  useEffect(() => {
    setTheme(getTheme('light'));
  }, []);

  return (
    <Form className={styles.container} onSubmit={handleSubmit}>
      <div className={cn([styles.form, 'p-xl flex align-center column'])}>
        <img
          alt="faciliciti-logo"
          src={Logo}
          className={cn([styles.logo, 'mb-m'])}
        />
        <div className="mb-l">
          <Typography
            variant="h1"
            component="p"
            className="text-center"
            fontWeight={500}
          >
            {t('forgotPassword.title')}
          </Typography>
          <Typography
            variant="body2"
            component="p"
            className="text-center"
            color="grey.300"
            fontWeight={300}
          >
            {t('forgotPassword.description')}
          </Typography>
        </div>
        <TextInput
          name="email"
          label={t('forgotPassword.email')}
          source="email"
          validate={[required(), email('ra.validation.email')]}
          onChange={(e) => setEmail(e.target.value)}
          fullWidth
        />
        <SaveButton
          label={t('forgotPassword.action')}
          type="submit"
          icon={null}
          fullWidth
        />
      </div>
    </Form>
  );
}

export default ForgotPassword;
