import { HttpError } from 'react-admin';
import client from '../client';
import formatErrorMessage from '../../utils/formatErrorMessage';
import { formatGetListApiResponse } from '../../utils/formatData';

const dataProvider = {
  getList: (resource, params) => new Promise((resolve, reject) => {
    const url = `/${resource}`;
    const filterObject = Object.entries(params?.filter)?.reduce((acc, [key, filterValue]) => {
      if (filterValue) {
        if (key === 'all') {
          return { ...acc, filter: filterValue };
        }
        return { ...acc, [key]: filterValue };
      }
      return acc;
    }, null);

    client.get(url, {
      params: {
        page: params?.pagination?.page,
        perPage: params?.pagination?.perPage,
        ...filterObject,
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    }).then((response) => {
      formatGetListApiResponse({ resource, response, resolve });
    })
      .catch((err) => {
        reject(new HttpError(
          formatErrorMessage(err),
          err?.response?.status,
          err?.response?.data,
        ));
      });
  }),
  getOne: (resource, params) => new Promise((resolve, reject) => {
    const url = `/${resource}/${params.id}`;
    client.get(url, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    }).then(({ data }) => {
      resolve({ data: { ...data, id: data?.uuid || data?.id, id_short: data?.id } });
    })
      .catch((err) => {
        reject(new HttpError(
          formatErrorMessage(err),
          err?.response?.status,
          err?.response?.data,
        ));
      });
  }),
  update: (resource, params) => new Promise((resolve, reject) => {
    const url = `/${resource}/${params.id}`;
    const method = params?.meta?.httpMethod || 'put';
    client?.[method](url, params.data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    }).then((response) => {
      const updated = Object?.values(response?.data)?.[0];
      resolve({ data: { ...updated, id: updated?.uuid, id_short: updated?.id } });
    })
      .catch((err) => {
        reject(new HttpError(
          formatErrorMessage(err),
          err?.response?.status,
          err?.response?.data,
        ));
      });
  }),
  create: (resource, params) => new Promise((resolve, reject) => {
    const url = `/${resource}`;
    client.post(url, params.data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    }).then((response) => {
      const added = Object?.values(response?.data)?.[0];
      resolve({ data: { ...added, id: added?.uuid, id_short: added?.id } });
    })
      .catch((err) => reject(new HttpError(
        formatErrorMessage(err),
        err?.response?.status,
        err?.response?.data,
      )));
  }),
  delete: (resource, params) => new Promise((resolve, reject) => {
    const url = `/${resource}/${params.id}`;
    client.delete(url, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      data: params?.meta,
    }).then(() => resolve({ data: params }))
      .catch((err) => reject(new HttpError(
        formatErrorMessage(err),
        err?.response?.status,
        err?.response?.data,
      )));
  }),
  deleteMany: (resource, params) => new Promise((resolve, reject) => {
    const deleteRequests = params?.ids?.map((id) => {
      const url = `/${resource}/${id}`;
      return client.delete(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        data: params?.meta,
      });
    });
    Promise.all(deleteRequests).then(() => resolve({ data: params?.ids }))
      .catch((err) => reject(new HttpError(
        formatErrorMessage(err),
        err?.response?.status,
        err?.response?.data,
      )));
  }),
};

export default dataProvider;
