import React from 'react';
import { Logout, useGetIdentity, UserMenu } from 'react-admin';
import Typography from '@mui/material/Typography';

function CustomUserMenu(props) {
  const { identity } = useGetIdentity();
  return (
  // eslint-disable-next-line react/jsx-props-no-spreading
    <UserMenu {...props}>
      <div className="ph-m flex column justify-center align-center">
        <div className="mv-s">
          <Typography
            style={{ fontWeight: 500, textTransform: 'capitalize' }}
            variant="body2"
            component="p"
          >
            {`${identity?.data?.firstName || ''} ${identity?.data?.lastName?.toUpperCase() || ''}`}
          </Typography>
        </div>
        <Logout />
      </div>
    </UserMenu>
  );
}

export default CustomUserMenu;
