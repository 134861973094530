const RaLayout = {
  styleOverrides: {
    root: {
      '& .RaLayout-appFrame': {
        marginTop: 80,
      },
      '& .RaLayout-content': {
        paddingBottom: 70,
      },
    },
  },
};

export default RaLayout;
