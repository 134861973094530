import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { useMemo } from 'react';
import { useTheme } from 'react-admin';
// Images
import IlluWhite from '../../assets/images/illustrations/general_assembly-white.png';
import Illu from '../../assets/images/illustrations/general_assembly.png';

function Dashboard() {
  const [theme] = useTheme();
  const illustration = useMemo(() => (
    theme?.palette?.mode === 'dark' ? IlluWhite : Illu
  ), [theme]);

  return (
    <Card>
      <CardContent>
        <div className="flex align-center text-center column pr-m pl-m">
          <Typography variant="h1" component="h1" fontWeight={500}>Bienvenu ! </Typography>
          <div className="flex wrap">
            <img alt="home-illustration" src={illustration} style={{ width: '100%', maxWidth: 500 }} />
          </div>
        </div>
      </CardContent>
    </Card>
  );
}

export default Dashboard;
