const resetPassword = {
  title: 'Réinitialiser le mot de passe',
  password: 'Mot de passe',
  passwordBis: 'Confirmation du mot de passe',
  action: 'Réinitialiser',
  success:
      'Votre mot de passe a bien été modifié, vous pouvez maintenant vous connecter.',
  appSuccess:
      'Votre mot de passe a bien été modifié, '
      + 'vous pouvez maintenant ouvrir votre application Faciliciti pour vous connecter.',
};
export default resetPassword;
