import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Fade from '@mui/material/Fade';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {
  Form,
  required,
  TextInput,
  useDelete,
  useDeleteMany,
  useListContext,
  useNotify,
  useRecordContext,
  useRedirect,
  useTranslate,
} from 'react-admin';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  borderRadius: 3,
  boxShadow: 24,
  p: 4,
};

function CancelEventModal({ open, close, isMulti }) {
  const [cancelReason, setCancelReason] = useState('');
  const record = useRecordContext();
  const notify = useNotify();
  const { selectedIds } = useListContext();
  const redirect = useRedirect();

  const t = useTranslate();

  const [deleteOne] = useDelete(
    'events',
    { id: record?.id, meta: { cancelReason } },
    {
      mutationMode: 'undoable',
      onSuccess: () => {
        close();
        redirect('/events');
        notify(t('news.cancelSuccess'), { undoable: true });
      },
      onError: () => {
        close();
        notify(t('errors.cancelEvent'));
      },
    },
  );
  const [deleteMany] = useDeleteMany(
    'events',
    { ids: selectedIds, meta: { cancelReason } },
    {
      mutationMode: 'undoable',
      onSuccess: () => {
        close();
        notify(t('news.cancelSuccess'), { undoable: true });
      },
      onError: () => {
        close();
        notify(t('errors.cancelEvent'));
      },
    },
  );

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={close}
      closeAfterTransition
    >
      <Fade in={open}>
        <Box sx={style}>
          <div className="mb-s">
            <Typography
              id="transition-modal-title"
              variant="h2"
              component="h2"
            >
              {t('news.cancelModal.title')}
            </Typography>
          </div>
          <Typography
            id="transition-modal-description"
            variant="body2"
            style={{ fontStyle: 'italic' }}
            fontWeight={100}
          >
            {t('news.cancelModal.description')}
          </Typography>
          <div className="mt-l">
            <Form>
              <TextInput
                name="cancel-event-reason"
                label={t('news.cancelModal.cancelReason')}
                source="cancelReason"
                validate={[required()]}
                onChange={(e) => setCancelReason(e.target.value)}
                fullWidth
                multiline
              />
            </Form>
          </div>
          <div className="flex justify-space-between full-width mt-l">
            <Button
              variant="outlined"
              color="primary"
              onClick={close}
            >
              {t('news.cancelModal.cancel')}
            </Button>
            <Button
              variant="contained"
              color="error"
              disabled={!cancelReason}
              onClick={() => (isMulti ? deleteMany() : deleteOne())}
            >
              {t('news.cancelModal.validate')}
            </Button>
          </div>
        </Box>
      </Fade>
    </Modal>
  );
}

CancelEventModal.propTypes = {
  open: PropTypes.bool.isRequired,
  isMulti: PropTypes.bool,
  close: PropTypes.func.isRequired,
};

CancelEventModal.defaultProps = {
  isMulti: false,
};

export default CancelEventModal;
