import { addMinutes, format } from 'date-fns';

const toUTCDate = (date) => (date ? addMinutes(new Date(date), new Date(date).getTimezoneOffset()) : null);

/**
 * Create form data for publication
 * @param data
 * @returns {FormData}
 */
export const formatPublicationData = (data) => {
  const formData = new FormData();
  if (data?.picture?.rawFile) {
    formData?.append('picture', data?.picture?.rawFile, data?.picture?.picture);
  }
  if (data?.publishDate) {
    formData?.append('publishDate', format(toUTCDate(data?.publishDate), 'yyyy-MM-dd HH:mm:ss'));
  }
  if (data?.unpublishDate) {
    formData?.append('unpublishDate', format(toUTCDate(data?.unpublishDate), 'yyyy-MM-dd HH:mm:ss'));
  }
  formData?.append('promote', data?.promote ? 1 : 0);
  formData?.append('isActive', data?.isActive ? 1 : 0);
  formData?.append('description', data?.description);
  const condominiumIds = data?.condominiums?.map((item) => (item?.id ? item?.id : item));
  formData?.append('condominiumIds', condominiumIds?.join(','));
  return formData;
};

/**
 * Create form data for event
 * @param data
 * @returns {FormData}
 */
export const formatEventData = (data) => {
  const formData = new FormData();
  if (data?.picture?.rawFile) {
    formData.append('picture', data?.picture?.rawFile, data?.picture?.picture);
  }
  if (data?.publishDate) {
    formData?.append('publishDate', format(toUTCDate(new Date(data?.publishDate)), 'yyyy-MM-dd HH:mm:ss'));
  }
  if (data?.startDate) {
    formData?.append('startDate', format(toUTCDate(new Date(data?.startDate)), 'yyyy-MM-dd HH:mm:ss'));
  }
  if (data?.endDate) {
    formData?.append('endDate', format(toUTCDate(new Date(data?.endDate)), 'yyyy-MM-dd HH:mm:ss'));
  }
  formData?.append('description', data?.description);
  formData?.append('title', data?.title);
  formData?.append('isActive', data?.isActive ? 1 : 0);
  const condominiumIds = data?.condominiums?.map((item) => (item?.id ? item?.id : item));
  formData?.append('condominiumIds', condominiumIds?.join(','));
  if (data?.street) {
    formData?.append('street', data?.street);
  }
  if (data?.zipCode) {
    formData?.append('zipCode', data?.zipCode);
  }
  if (data?.city) {
    formData?.append('city', data?.city);
  }
  return formData;
};

/**
 * Group appointments data to group them by date
 * @param values
 * @returns [{[date] : [appointments], id: [date]}, ...]
 */
const groupAppointmentsByDates = (values) => values?.reduce((acc, appointment) => {
  const formattedStartDate = format(new Date(appointment?.startDate), 'dd/MM/yyyy');
  const valuesForCurrentDate = acc?.filter((item) => Object.keys(item)?.includes(formattedStartDate));
  if (valuesForCurrentDate?.length) {
    const valuesForOtherDates = acc?.filter((item) => !Object.keys(item)?.includes(formattedStartDate));
    const currentDateObject = valuesForCurrentDate?.[0];
    return valuesForOtherDates?.concat([
      {
        ...currentDateObject,
        [formattedStartDate]: currentDateObject?.[formattedStartDate]?.concat([appointment]),
      },
    ]);
  }
  return acc?.concat([{ [formattedStartDate]: [appointment], id: formattedStartDate }]);
}, []);

/**
 * Format data received from API for them to be displayed easily on RA List views
 * @param resource
 * @param response
 * @param resolve
 * @returns {*}
 */
export const formatGetListApiResponse = ({ resource, response, resolve }) => {
  switch (resource) {
    case 'appointments': {
      let values = Object.values(response?.data)?.reduce((acc, item) => (Array.isArray(item) ? item : acc), []);
      values = groupAppointmentsByDates(values);
      return resolve({
        data: values,
        total: response?.data.count,
      });
    }
    default: {
      const values = Object.values(response?.data)?.reduce((acc, item) => (Array.isArray(item) ? item : acc), []);
      return resolve({
        data: response?.data ? values.map((item) => ({
          ...item,
          id: item?.uuid ? item?.uuid : item?.id,
          id_short: item?.id,
        })) : [],
        total: response?.data.count,
      });
    }
  }
};
