const reports = {
  to_process: 'À traiter',
  processed: 'Traité',
  deleted: 'Supprimé',
  validate: 'Valider',
  delete: 'Supprimer',
  confirmationModal: {
    title: 'Êtes-vous sûr(e) de vouloir supprimer ce message signalé ?',
    content: 'En continuant, le message sera supprimé de la conversation et ne pourra pas être affiché de nouveau.',
  },
  advertModal: {
    title: 'Êtes-vous sûr(e) de vouloir supprimer l\'annonce signalée ?',
    content: 'En continuant, l\'annonce sera supprimée et ne pourra pas être affichée de nouveau.',
  },
  actualAdvert: 'Etat actuel de l\'annonce',
  pictureLink: 'Lien vers l\'image',
};

export default reports;
