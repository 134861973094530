import * as React from 'react';
import { useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
// React admin
import {
  SimpleForm,
  required,
  SaveButton,
  useTranslate,
  PasswordInput,
  useTheme,
  useNotify,
} from 'react-admin';
// Material UI
import Typography from '@mui/material/Typography';
// Style
import { useMutation } from 'react-query';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import styles from '../styles/pages/connection.module.css';
import getTheme from '../styles/theme';
// Utils
import cn from '../utils/cn';
// Images
import Logo from '../assets/images/logo-small.png';
import AuthService from '../services/AuthService';
// utils
import { PASSWORD_REG } from '../utils/regex';

const validationSchema = Yup.object({
  password: Yup.string()
    .min(8, 'ra.validation.password')
    .matches(PASSWORD_REG, 'ra.validation.password')
    .required('ra.validation.required'),
  passwordBis: Yup.string()
    .oneOf([Yup.ref('password'), null], 'ra.validation.passwords')
    .required('ra.validation.required'),
});

function ResetPassword() {
  const [, setTheme] = useTheme();
  const [password, setPassword] = useState('');
  const [, setPasswordBis] = useState('');
  const notify = useNotify();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const t = useTranslate();

  // queries
  const resetPasswordMutation = useMutation((data) => AuthService.resetPassword(data), {
    onError: () => {
      notify('errors.resetPassword', { type: 'error' });
    },
    onSuccess: () => {
      if (!location?.pathname?.includes('app-reset-password')) {
        notify('resetPassword.success', { type: 'success' });
        return setTimeout(() => navigate('/#/login', { replace: true }), 4000);
      }
      return notify('resetPassword.appSuccess', { type: 'success', autoHideDuration: 30000 });
    },
  });

  const handleSubmit = () => {
    resetPasswordMutation.mutate({ password, token: searchParams.get('token') });
  };

  useEffect(() => {
    setTheme(getTheme('light'));
  }, []);

  return (
    <SimpleForm
      toolbar={false}
      className={styles.container}
      onSubmit={handleSubmit}
      resolver={yupResolver(validationSchema)}
    >
      <div className={cn([styles.form, 'p-xl flex align-center column'])}>
        <img
          alt="faciliciti-logo"
          src={Logo}
          className={cn([styles.logo, 'mb-m'])}
        />
        <div className="mb-l">
          <Typography
            variant="h1"
            component="p"
            className="text-center"
            fontWeight={500}
          >
            {t('resetPassword.title')}
          </Typography>
        </div>
        <div className="mt-m full-width">
          <PasswordInput
            label={t('resetPassword.password')}
            name="password"
            source="password"
            validate={[required()]}
            onChange={(e) => setPassword(e.target.value)}
            fullWidth
          />
        </div>
        <div className="mt-m full-width">
          <PasswordInput
            label={t('resetPassword.passwordBis')}
            name="passwordBis"
            source="passwordBis"
            validate={[required()]}
            onChange={(e) => setPasswordBis(e.target.value)}
            fullWidth
          />
        </div>
        <SaveButton
          label={t('resetPassword.action')}
          type="submit"
          icon={null}
          fullWidth
        />
      </div>
    </SimpleForm>
  );
}

export default ResetPassword;
