import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
// RA
import { ChipField, useTranslate } from 'react-admin';
import Typography from '@mui/material/Typography';

function StatusActiveField({ isActive, isCanceled, description }) {
  // hooks
  const t = useTranslate();
  // methods
  const color = useMemo(() => {
    if (isCanceled) {
      return 'error';
    }
    if (isActive) {
      return 'success';
    }
    return 'warning';
  });
  const label = useMemo(() => {
    if (isCanceled) {
      return t('labels.canceled');
    }
    if (isActive) {
      return t('labels.active');
    }
    return t('labels.inactive');
  });
  return (
    <>
      <ChipField
        source="isActive"
        record={{ isActive: label }}
        color={color}
        variant="outlined"
      />
      {description ? (
        <div className="mt-s">
          <Typography
            style={{ fontStyle: 'italic', fontWeight: 100, lineHeight: '0.75rem' }}
            variant="caption"
            component="p"
          >
            {description}
          </Typography>
        </div>
      ) : null}
    </>
  );
}

StatusActiveField.propTypes = {
  isActive: PropTypes.bool.isRequired,
  isCanceled: PropTypes.bool,
  description: PropTypes.string,
};

StatusActiveField.defaultProps = {
  isCanceled: false,
  description: null,
};

export default StatusActiveField;
