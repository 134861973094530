import {
  Datagrid,
  List,
  TextField,
  SimpleList,
  useTranslate,
  DateField,
  FunctionField, NumberField,
} from 'react-admin';
import { useMediaQuery } from '@mui/material';
import { format } from 'date-fns';
// components
import Button from '@mui/material/Button';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import StringListField from '../../molecules/StringListField';
import StatusActiveField from '../../molecules/StatusActiveField';
import CancelEventModal from '../modals/CancelEventModal';

const dateFieldWithHour = {
  day: 'numeric',
  month: 'numeric',
  year: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
};

function CancelEventButton({ openCancelModal }) {
  const t = useTranslate();
  return (
    <Button
      color="error"
      onClick={openCancelModal}
    >
      {t('news.cancelActionShort')}
    </Button>
  );
}

CancelEventButton.propTypes = {
  openCancelModal: PropTypes.func.isRequired,
};

function EventList() {
  const [openCancel, setOpenCancel] = useState(false);

  const isSmall = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const t = useTranslate();

  const handleCloseModal = () => setOpenCancel(false);
  const handleOpenModal = () => setOpenCancel(true);

  const renderAddressField = (record) => {
    let address = t('news.emptyAddress');
    if (record.street || record.zipCode || record.city) {
      address = `${record?.street}, ${record?.zipCode} ${record?.city}`;
    }
    return (
      <div>
        <p className="m-0">{address}</p>
      </div>
    );
  };

  const renderEventDateField = (record) => {
    const formattedStartDate = record.startDate ? format(new Date(record.startDate), 'dd/MM/yyyy HH:mm') : '';
    const formattedEndDate = record.endDate ? format(new Date(record.endDate), 'dd/MM/yyyy HH:mm') : '';
    return (
      <div>
        <p className="m-0">{`${t('labels.from')} ${formattedStartDate}`}</p>
        <p className="m-0">{`${t('labels.to')} ${formattedEndDate}`}</p>
      </div>
    );
  };

  const renderStatusAndPublicationDate = (record) => (
    <div className="flex column">
      <p className="m-0">{record?.isActive ? t('labels.active') : t('labels.inactive')}</p>
      <p className="m-0">{t('labels.publishAt')}</p>
      <p className="m-0">{record.publishDate ? format(new Date(record.publishDate), 'dd/mm/yyyy') : ''}</p>
    </div>
  );

  return (
    <List exporter={false}>
      <>
        <CancelEventModal
          open={openCancel}
          close={handleCloseModal}
          isMulti
        />
        {isSmall
          ? (
            <SimpleList
              primaryText={(record) => record.title}
              secondaryText={renderEventDateField}
              tertiaryText={renderStatusAndPublicationDate}
              leftIcon={(record) => record?.countAttendees}
            />
          )
          : (
            <Datagrid
              isRowSelectable={(record) => !!record.id}
              bulkActionButtons={<CancelEventButton openCancelModal={handleOpenModal} />}
              rowClick="edit"
            >
              <DateField
                sortable={false}
                source="publishDate"
                label="labels.publishDate"
                options={dateFieldWithHour}
              />
              <TextField sortable={false} source="title" label="labels.title" />
              <FunctionField
                textAlign="center"
                label="labels.date"
                render={renderEventDateField}
              />
              <FunctionField
                textAlign="center"
                label="labels.address"
                render={renderAddressField}
              />
              <FunctionField
                textAlign="center"
                label="labels.condominiums"
                render={(record) => (
                  <StringListField
                    listField={record?.condominiums?.map(({ entitled }) => entitled)}
                  />
                )}
              />
              <NumberField
                textAlign="center"
                source="countAttendees"
                label="labels.countAttendees"
              />
              <FunctionField
                textAlign="center"
                label="labels.isActive"
                render={(record) => (
                  <StatusActiveField
                    isActive={record?.isActive}
                    isCanceled={record?.isDeleted}
                    description={record?.isDeleted ? record?.cancelReason : null}
                  />
                )}
              />
            </Datagrid>
          )}
      </>
    </List>
  );
}

export default EventList;
