import client from './client';

/**
 * @description send email to retrieve lost password
 * @param email
 * @returns {Promise<AxiosResponse<any>>}
 */
const forgotPassword = (email) => client.post('/admin/reset-password', { email });

/**
 * @description change password with temporary password
 * @param password
 * @param token
 * @returns {Promise<AxiosResponse<any>>}
 */
const resetPassword = ({ password, token }) => client.post('/reset-password/reset', { password, token });

export default {
  forgotPassword,
  resetPassword,
};
