const colorsDark = {
  primary: {
    main: '#A87FF6',
    light: '#C6A8FF',
    dark: '#906AD9',
    contrastText: 'rgba(0,0,0,0.87)',
  },
  secondary: {
    main: '#5A9BBB',
    light: '#6EB5D8',
    dark: '#47829E',
    contrastText: 'rgba(0,0,0,0.87)',
  },
  warning: {
    main: '#FFBF3D',
    light: '#FFCD69',
    dark: '#DDA22A',
    contrastText: 'rgba(0,0,0,0.87)',
  },
  error: {
    main: '#B11D38',
    light: '#F1A7B4',
    dark: '#841529',
    contrastText: '#fff',
  },
  info: {
    main: '#83D0F5',
    light: '#ABE3FD',
    dark: '#6EB5D8',
    contrastText: 'rgba(0,0,0,0.87)',
  },
  success: {
    main: '#1CB79B',
    light: '#ABF2E5',
    dark: '#158974',
    contrastText: 'rgba(0,0,0,0.87)',
  },
  background: {
    paper: '#38383A',
    default: '#1b1b1d',
  },
  grey: {
    500: '#9E9E9E',
    300: '#e0e0e0',
    200: '#eeeeee',
    100: '#f5f5f5',
    50: '#fafafa',
  },
  common: {
    black: '#000',
    white: '#fff',
  },
};

export default colorsDark;
