import {
  Datagrid,
  List,
  TextField,
  SimpleList,
  useTranslate,
  BooleanField,
  DateField,
  ImageField,
  useTheme,
  FunctionField,
} from 'react-admin';
import { useMediaQuery } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import GradeIcon from '@mui/icons-material/Grade';
import Tooltip from '@mui/material/Tooltip';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { useMemo } from 'react';
import { format } from 'date-fns';
// components
import StringListField from '../../molecules/StringListField';
import StatusActiveField from '../../molecules/StatusActiveField';

function PublicationList() {
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const t = useTranslate();
  const [theme] = useTheme();
  const trueColor = useMemo(() => (
    theme?.palette?.success?.main
  ), [theme]);
  const falseColor = useMemo(() => (
    theme?.palette?.error?.main
  ), [theme]);

  const renderBooleanField = (record, source) => (
    <BooleanField
      TrueIcon={CheckCircleOutlineIcon}
      FalseIcon={HighlightOffIcon}
      source={source}
      record={record}
      label="labels.promote"
      sx={{
        color: record?.[source] ? trueColor : falseColor,
      }}
    />
  );

  const renderSmallAvatar = (record) => (
    <img
      className="object-contain border-radius-normal mr-s"
      style={{
        width: 50,
      }}
      alt=""
      src={record?.picture}
    />
  );

  const renderSmallBooleanValues = (record) => (
    <div className="flex column">
      {record?.promote ? (
        <Tooltip title={t('labels.promote')}>
          <GradeIcon color="primary" />
        </Tooltip>
      ) : null}
      {record?.isActive ? t('labels.active') : t('labels.inactive')}
    </div>
  );

  return (
    <List exporter={false}>
      {isSmall
        ? (
          <SimpleList
            primaryText={(record) => record.description}
            secondaryText={(record) => `${
              t('labels.publishDate')} : ${format(new Date(record.publishDate), 'dd/MM/yyyy')} - ${
              t('labels.unPublishDate')} : ${format(new Date(record.unpublishDate), 'dd/MM/yyyy')}`}
            tertiaryText={renderSmallBooleanValues}
            leftIcon={renderSmallAvatar}
          />
        )
        : (
          <Datagrid rowClick="edit">
            <ImageField
              sortable={false}
              textAlign="center"
              source="picture"
              title={t('labels.picture')}
              label="labels.picture"
            />
            <TextField sortable={false} source="description" label="labels.description" />
            <DateField sortable={false} source="publishDate" label="labels.publishDate" />
            <DateField sortable={false} source="unpublishDate" label="labels.unPublishDate" />
            <FunctionField
              textAlign="center"
              label="labels.condominiums"
              render={(record) => <StringListField listField={record?.condominiums?.map(({ entitled }) => entitled)} />}
            />
            <FunctionField label="labels.promote" render={(record) => renderBooleanField(record, 'promote')} />
            <FunctionField
              label="labels.isActive"
              render={(record) => <StatusActiveField isActive={record?.isActive} />}
            />
          </Datagrid>
        )}
    </List>
  );
}

export default PublicationList;
