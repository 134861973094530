const shadows = {
  0: 'none',
  1: 'none',
  2: 'none',
  3: 'none',
  4: 'none',
  5: 'none',
  6: 'none',
  7: 'none',
  8: 'none',
  9: 'none',
  10: 'none',
  11: 'none',
  12: 'none',
  13: 'none',
  14: 'none',
  15: 'none',
  16: 'none',
  17: 'none',
  18: 'none',
  19: 'none',
  20: 'none',
  21: 'none',
  22: 'none',
  23: 'none',
  24: 'none',
};

export default shadows;
