import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
// ra
import {
  AutocompleteArrayInput,
  BooleanInput,
  DateTimeInput,
  FileInput,
  required,
  SimpleForm,
  TextInput,
  useNotify,
  useRecordContext,
  useTranslate,
  Datagrid,
  TextField,
  ArrayField,
  EmailField,
  FunctionField,
  SimpleList,
} from 'react-admin';
// material UI
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { useMediaQuery } from '@mui/material';
// hooks
import { useQuery } from 'react-query';
// Service
import CondominiumService from '../../../services/CondominiumService';
// Utils
import formatErrorMessage from '../../../utils/formatErrorMessage';
// Components
import GooglePlacesAutocomplete from '../../molecules/GooglePlacesAutocomplete';
import StringListField from '../../molecules/StringListField';

function Preview({ record }) {
  return (
    <img
      alt="preview"
      src={record?.src || record}
      className="object-contain"
      style={{ width: 100 }}
    />
  );
}

Preview.propTypes = {
  record: PropTypes.shape({
    src: PropTypes.string,
  }).isRequired,
};

function EventForm({ openCancelModal }) {
  // hooks
  const notify = useNotify();
  const record = useRecordContext();
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const t = useTranslate();

  // Queries
  const getCondominiumsQuery = useQuery('getCondominiums', () => CondominiumService.getCondominium(), {
    onError: (err) => notify(formatErrorMessage(err), { type: 'error' }),
  });

  // Methods
  const condominiumChoices = useMemo(() => {
    const allOption = [{
      entitled: t('labels.all'),
      id: getCondominiumsQuery?.data?.data?.condominiums?.map((item) => item?.id),
    }];
    return allOption.concat(getCondominiumsQuery?.data?.data?.condominiums || []);
  }, [getCondominiumsQuery?.data]);

  return (
    <>
      {record?.id ? (
        <Button
          sx={{
            position: 'absolute',
            right: 10,
            top: -30,
          }}
          variant="contained"
          color="error"
          disabled={record?.isDeleted}
          onClick={openCancelModal}
        >
          {record?.isDeleted ? t('news.canceled') : t('news.cancelAction')}
        </Button>
      ) : null}
      <SimpleForm>
        <div>
          <TextInput
            source="title"
            name="title"
            label={t('labels.title')}
            fullWidth
            validate={[required()]}
          />
          <div className="flex row wrap">
            <div className="mr-l">
              <DateTimeInput
                source="publishDate"
                name="publishDate"
                defaultValue={new Date()}
                label={t('labels.publishDate')}
                validate={[required()]}
              />
            </div>
            <BooleanInput
              name="isActive"
              source="isActive"
              label={t('labels.active')}
            />
          </div>
          <div className="flex row wrap">
            <div className="mr-m">
              <DateTimeInput
                source="startDate"
                name="startDate"
                label={t('labels.startDate')}
                validate={[required()]}
              />
            </div>
            <div className="mr-l">
              <DateTimeInput
                source="endDate"
                name="endDate"
                label={t('labels.endDate')}
                validate={[required()]}
              />
            </div>
          </div>
          <div className="mb-l">
            <GooglePlacesAutocomplete
              source="address"
              label={t('labels.address')}
            />
          </div>
          <AutocompleteArrayInput
            name="condominiums"
            source="condominiums"
            choices={condominiumChoices}
            label={t('labels.condominiums')}
            optionText="entitled"
            format={(value) => {
              if (value?.find((val) => Array.isArray(val))) {
                return value?.find((val) => Array.isArray(val));
              }
              return (value?.[0]?.id ? value?.map((v) => v.id) : value);
            }}
            validate={[required()]}
            loading={getCondominiumsQuery.isLoading}
            noOptionsText={t('labels.noResults')}
          />
          <TextInput
            name="description"
            source="description"
            multiline
            fullWidth
            validate={[required()]}
          />
          <FileInput
            name="picture"
            source="picture"
            label={t('labels.picture')}
            accept="image/png, image/jpeg"
            validate={[required()]}
          >
            <Preview source="src" />
          </FileInput>
        </div>
      </SimpleForm>
      {
        record?.attendees?.length
          ? (
            <div className="pv-m">
              <div className="mh-m mb-m">
                <Typography variant="h2" fontWeight={500} color="primary">
                  {`${t('news.attendees')} (${record?.attendees?.length})`}
                </Typography>
              </div>
              <ArrayField source="attendees">
                {isSmall ? (
                  <SimpleList
                    primaryText={(attendee) => `${attendee?.firstName || ''} ${attendee?.lastName || ''}`}
                    secondaryText={(attendee) => attendee?.email}
                    tertiaryText={(attendee) => attendee?.phone}

                  />
                ) : (
                  <Datagrid bulkActionButtons={false}>
                    <TextField source="firstName" label="labels.firstName" />
                    <TextField source="lastName" label="labels.lastName" />
                    <EmailField source="email" label="labels.email" />
                    <FunctionField
                      textAlign="center"
                      label="labels.condominiums"
                      render={(attendee) => (
                        <StringListField
                          listField={attendee?.condominiums?.map(({ entitled }) => entitled)}
                        />
                      )}
                    />
                    <TextField source="phone" label={t('labels.phone')} sortable={false} />
                  </Datagrid>
                )}
              </ArrayField>
            </div>
          ) : null
      }
    </>
  );
}

EventForm.propTypes = {
  openCancelModal: PropTypes.func.isRequired,
};

export default EventForm;
