import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@mui/material/Tooltip';
import { TextField } from 'react-admin';

function StringListField({ listField }) {
  const condominiumString = listField?.slice(0, 2)?.join(', ');
  const allCondominiumString = listField?.join('\n');
  return (
    <Tooltip title={<div style={{ whiteSpace: 'pre-line' }}>{allCondominiumString}</div>} arrow>
      <div>
        <TextField
          source="value"
          record={{ value: condominiumString }}
        />
      </div>
    </Tooltip>
  );
}

StringListField.propTypes = {
  listField: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default StringListField;
