import {
  Edit, ListButton,
} from 'react-admin';
import PublicationForm from '../forms/PublicationForm';
import { formatPublicationData } from '../../../utils/formatData';

function PublicationEdit() {
  return (
    <div className="d-flex">
      <ListButton />
      <Edit
        transform={formatPublicationData}
        mutationOptions={{ meta: { httpMethod: 'post' } }}
      >
        <PublicationForm />
      </Edit>
    </div>
  );
}

export default PublicationEdit;
