const colorsLight = {
  primary: {
    light: '#A87FF6',
    main: '#906AD9',
    dark: '#7956BC',
    contrastText: '#fff',
  },
  secondary: {
    main: '#47829E',
    light: '#5A9BBB',
    dark: '#376980',
    contrastText: '#fff',
  },
  warning: {
    main: '#FFBF3D',
    light: '#FFCD69',
    dark: '#DDA22A',
    contrastText: '#fff',
  },
  error: {
    main: '#B11D38',
    light: '#F1A7B4',
    dark: '#841529',
    contrastText: '#fff',
  },
  info: {
    main: '#83D0F5',
    light: '#ABE3FD',
    dark: '#6EB5D8',
    contrastText: '#fff',
  },
  success: {
    main: '#1CB79B',
    light: '#ABF2E5',
    dark: '#158974',
    contrastText: '#fff',
  },
  grey: {
    500: '#666666',
    300: '#AAAAAA',
    200: '#CCCCCC',
    100: '#EEEEEE',
    50: '#F8F8F8',
  },
  common: {
    black: '#000',
    white: '#fff',
  },
  background: {
    paper: '#F8F8F8',
    default: '#EEEEEE',
  },
};

export default colorsLight;
