import {
  Create, ListButton,
} from 'react-admin';
import * as React from 'react';
import UserForm from '../forms/UserForm';

export function UserCreate() {
  return (
    <div className="d-flex">
      <ListButton />
      <Create>
        <UserForm />
      </Create>
    </div>
  );
}

export default UserCreate;
