import React, { useCallback, useEffect, useState } from 'react';
import { Button, CardContent } from '@mui/material';
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import {
  Form, useTranslate, useNotify, useRecordContext, useRefresh,
} from 'react-admin';
import { useMutation, useQuery } from 'react-query';
import CondoAndGroupFormLine from '../../molecules/CondoAndGroupFormLine';
import CondominiumService from '../../../services/CondominiumService';
import formatErrorMessage from '../../../utils/formatErrorMessage';
import GroupService from '../../../services/GroupService';
import UserService from '../../../services/UserService';

function UserRoleForm() {
  // local state
  const [condoAndRole, setCondoAndRole] = useState([{ index: 0 }]);
  const [groups, setGroups] = useState([]);

  // hooks
  const t = useTranslate();
  const notify = useNotify();
  const record = useRecordContext();
  const refresh = useRefresh();

  // queries
  const putMainFacilitatorMutation = useMutation((data) => CondominiumService.putMainFacilitator(data), {
    onSuccess: () => refresh(),
    onError: (err) => {
      notify(formatErrorMessage(err), { type: 'error' });
    },
  }); const putUserCondominiumMutation = useMutation((data) => UserService.putUserCondominium(data), {
    onSuccess: () => refresh(),
    onError: (err) => {
      notify(formatErrorMessage(err), { type: 'error' });
    },
  });
  const postUserCondominiumMutation = useMutation((data) => UserService.postUserCondominium(data), {
    onSuccess: () => refresh(),
    onError: (err) => {
      notify(formatErrorMessage(err), { type: 'error' });
    },
  });
  const deleteUserCondominiumMutation = useMutation((data) => UserService.deleteUserCondominium(data), {
    onSuccess: () => refresh(),
    onError: (err) => {
      notify(formatErrorMessage(err), { type: 'error' });
    },
  });
  const getCondominiumsQuery = useQuery(['getCondominiums',
    deleteUserCondominiumMutation?.isError,
    putUserCondominiumMutation?.isError,
    putMainFacilitatorMutation?.isError,
    postUserCondominiumMutation?.isError], () => CondominiumService.getCondominium(), {
    onError: (err) => notify(formatErrorMessage(err), { type: 'error' }),
  });
  const getGroupsQuery = useQuery(['getGroups',
    deleteUserCondominiumMutation?.isError,
    putUserCondominiumMutation?.isError,
    putMainFacilitatorMutation?.isError,
    postUserCondominiumMutation?.isError], () => GroupService.getGroups(), {
    onSuccess: (data) => {
      if (!groups?.length) {
        setGroups(data?.data?.groupList?.groupInfos);
      }
    },
    onError: (err) => notify(formatErrorMessage(err), { type: 'error' }),
  });

  // methods
  const handleMainFacilitatorClick = (condominiumId) => {
    putMainFacilitatorMutation.mutate({
      userId: record?.id,
      condominiumId,
    });
  };

  const handleNewCondoAndRole = (newValue) => {
    if (condoAndRole?.map(({ index }) => index)?.includes(newValue?.index)) {
      const currentCondoInRecord = record?.condominiums?.find(({ id }) => id === newValue?.condominium?.id);
      if (!currentCondoInRecord) {
        postUserCondominiumMutation.mutate({
          userId: record?.id,
          values: {
            groupId: newValue?.group?.id,
            condominiumId: newValue?.condominium?.id,
          },
        });
      }
      if (currentCondoInRecord && currentCondoInRecord?.groupCode !== newValue?.group?.code) {
        putUserCondominiumMutation.mutate({
          userId: record?.id,
          values: {
            groupId: newValue?.group?.id,
            condominiumId: newValue?.condominium?.id,
          },
        });
      }
      return setCondoAndRole(
        (state) => state?.filter(({ index }) => index !== newValue?.index)?.concat(newValue),
      );
    }
    return null;
  };

  const handleDeleteCondoAndRole = (indexToDelete) => {
    const valueToDelete = condoAndRole?.find(({ index }) => index === indexToDelete);
    const currentCondoInRecord = record?.condominiums?.find(({ id }) => id === valueToDelete?.condominium?.id);
    if (currentCondoInRecord) {
      deleteUserCondominiumMutation.mutate({
        userId: record?.id,
        condominiumId: valueToDelete?.condominium?.id,
      });
    }
    setCondoAndRole(
      (state) => state?.filter(({ index }) => index !== indexToDelete),
    );
  };

  const initValues = useCallback(({ groupsData, condominiums }) => {
    if (record?.condominiums?.length
        && groupsData?.length
        && condominiums?.length
    ) {
      setCondoAndRole(record.condominiums.map(({ id, groupCode }, index) => ({
        index,
        condominium: condominiums?.find(({ id: listId }) => listId === id),
        group: groupsData?.find(({ code }) => code === groupCode),
      })));
    }
  }, [record, setCondoAndRole]);

  const getDefaultValue = useCallback((index) => {
    const condoAndRoleValue = condoAndRole?.find(({ index: listIndex }) => listIndex === index);
    const isMainFacilitator = record?.condominiums?.find(
      ({ id }) => id === condoAndRoleValue?.condominium?.id,
    )?.isMainFacilitator || false;
    return {
      ...condoAndRoleValue,
      isMainFacilitator,
    };
  }, [record, condoAndRole]);

  // Add default values
  useEffect(() => {
    initValues({
      groupsData: getGroupsQuery?.data?.data?.groupList?.groupInfos,
      condominiums: getCondominiumsQuery?.data?.data?.condominiums,
    });
  }, [
    record,
    getGroupsQuery?.data?.data?.groupList?.groupInfos,
    getCondominiumsQuery?.data?.data?.condominiums,
  ]);
  // prevent selecting wrong group
  useEffect(() => {
    if (condoAndRole?.filter(({ group }) => group?.code === 'facilitator')?.length) {
      setGroups(getGroupsQuery?.data?.data?.groupList?.groupInfos?.filter(({ code }) => code === 'facilitator'));
    } else if (!condoAndRole?.length < 2) {
      setGroups(getGroupsQuery?.data?.data?.groupList?.groupInfos);
    } else {
      setGroups(getGroupsQuery?.data?.data?.groupList?.groupInfos?.filter(({ code }) => code !== 'facilitator'));
    }
  }, [condoAndRole, getGroupsQuery?.data?.data?.groupList?.groupInfos]);

  return (
    <Form
      mode="onChange"
      noValidate
    >
      <CardContent>
        { condoAndRole?.length
          ? condoAndRole?.map(({ index }) => (
            // eslint-disable-next-line react/no-array-index-key
            <div className="flex row wrap mv-m" key={`role-${index}`}>
              <CondoAndGroupFormLine
                showMainFacilitatorButton={!!record?.id}
                condominiums={getCondominiumsQuery?.data?.data?.condominiums || []}
                groups={groups || []}
                handleSubmit={handleNewCondoAndRole}
                index={index}
                defaultValue={getDefaultValue(index)}
                handleMainFacilitatorClick={handleMainFacilitatorClick}
                mainFacilitatorLoading={putMainFacilitatorMutation?.isLoading}
              />
              {getDefaultValue(index)?.isMainFacilitator ? null : (
                <Button
                  size="small"
                  color="error"
                  startIcon={<RemoveCircleOutlineOutlinedIcon />}
                  onClick={() => handleDeleteCondoAndRole(index)}
                >
                  {t('ra.action.delete')}
                </Button>
              )}
            </div>
          )) : null}
        <Button
          startIcon={<AddCircleOutlineOutlinedIcon />}
          onClick={() => setCondoAndRole((state) => state?.concat([{ index: state?.length }]))}
        >
          {t('ra.action.add')}
        </Button>
      </CardContent>
    </Form>
  );
}

UserRoleForm.propTypes = {

};

export default UserRoleForm;
