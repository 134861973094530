import axios from 'axios';

const client = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/api`,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  timeout: 30000,
});

export default client;

/**
 * Interceptor to add token in requests
 */
client.interceptors.request.use(async (config) => {
  const token = localStorage.getItem('token');
  const publicRoutes = [
    '/token/refresh',
    '/admin/auth',
    '/admin/reset-password',
    '/reset-password/reset',
  ];
  if (token && config.url !== '/token/refresh' && publicRoutes?.includes(config.url)) {
  // eslint-disable-next-line no-param-reassign
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

// /**
//  * Interceptor to handle refresh token
//  */
client.interceptors.response.use(
  (response) => response,
  async (error) => {
    const status = error.response ? error.response.status : null;
    const originalRequest = error.config;

    // eslint-disable-next-line no-underscore-dangle
    if (status !== 401 || error.response.config?.url === '/token/refresh' || originalRequest._retry) {
      localStorage.removeItem('token');
      localStorage.removeItem('refreshToken');
      return Promise.reject(error);
    }

    // eslint-disable-next-line no-underscore-dangle
    originalRequest._retry = true;

    try {
      // Get the new token from the API with the refresh_token saved in redux persisted state
      const refreshedTokenResult = await client.post(
        '/token/refresh',
        { refresh_token: localStorage.getItem('refreshToken') },
      );

      let newAxiosConfig = originalRequest;
      if (refreshedTokenResult?.data?.token) {
        // Change the failed call request config to change the Bearer token with the new one
        newAxiosConfig = Object.assign(originalRequest, {
          headers: {
            Authorization: `Bearer ${refreshedTokenResult?.data?.token}`,
          },
        });
        // Save in local storage the refreshed auth object
        localStorage.setItem('token', refreshedTokenResult?.data?.token);
      }
      if (refreshedTokenResult?.data?.refresh_token) {
        localStorage.setItem('refreshToken', refreshedTokenResult?.data?.refresh_token);
      }
      // retry the failed call with the new config
      return axios.request(newAxiosConfig);
    } catch (catchError) {
      localStorage.removeItem('token');
      localStorage.removeItem('refreshToken');
      return Promise.reject(catchError);
    }
  },
);
