import { defaultTheme } from 'react-admin';
import colorsLight from './colors/light';
import colorsDark from './colors/dark';
import shadows from './shadows/shadows';
import RaAppBar from './components/AppBar';
import RaSidebar from './components/Sidebar';
import RaFilterForm from './components/FilterForm';
import RaLayout from './components/Layout';
import typography from './fonts/typography';

const theme = (colorTheme = 'light') => {
  const colors = colorTheme === 'light' ? colorsLight : colorsDark;
  return ({
    ...defaultTheme,
    shadows,
    typography,
    palette: {
      mode: colorTheme,
      ...colors,
    },
    shape: {
      borderRadius: 5,
    },
    components: {
      RaAppBar,
      RaSidebar: RaSidebar(colors),
      RaLayout,
      RaFilterForm,
    },
  });
};

export default theme;
