import { useState } from 'react';
import * as React from 'react';
// ra
import {
  Edit, ListButton,
} from 'react-admin';
// Components
import EventForm from '../forms/EventForm';
import CancelEventModal from '../modals/CancelEventModal';
// Utils
import { formatEventData } from '../../../utils/formatData';

function EventEdit() {
  // local state
  const [openCancel, setOpenCancel] = useState(false);

  // methods
  const handleCloseModal = () => setOpenCancel(false);
  const handleOpenModal = () => setOpenCancel(true);

  return (
    <>
      <div className="flex row justify-space-between align-center full-width">
        <ListButton />
      </div>
      <Edit
        sx={{
          position: 'relative',
        }}
        transform={formatEventData}
        mutationOptions={{ meta: { httpMethod: 'post' } }}
      >
        <>
          <EventForm openCancelModal={handleOpenModal} />
          <CancelEventModal
            open={openCancel}
            close={handleCloseModal}
          />
        </>
      </Edit>
    </>
  );
}

export default EventEdit;
