import ra from './ra';
import errors from './errors';
import menu from './menu';
import forgotPassword from './forgotPassword';
import resetPassword from './resetPassword';
import labels from './labels';
import reports from './reports';
import news from './news';

export default
{
  ra,
  errors,
  menu,
  forgotPassword,
  resetPassword,
  labels,
  reports,
  news,
  'Request failed with status code 401': 'Merci de vous connecter pour continuer',
};
