const RaSidebar = (colors) => ({
  styleOverrides: {
    root: {
      borderRight: `1px solid ${colors.grey?.['200']}`,
      boxShadow: 'none',
    },
  },
});

export default RaSidebar;
