const RaAppBar = {
  styleOverrides: {
    root: {
      boxShadow: 'none',
      backdropFilter: 'blur(5px)',
      backgroundColor: 'transparent',
      '& .RaAppBar-toolbar': {
        height: 70,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
      },
    },
  },
};

export default RaAppBar;
