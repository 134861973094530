import client from './client';

/**
 * @description Update one of user condominium
 * @param userId: String
 * @param values: {groupId: number, condominiumId: number}
 * @returns {Promise<AxiosResponse<any>>}
 */
const putUserCondominium = ({ userId, values }) => client.put(`/users/${userId}/condominiums`, values);

/**
 * @description Add one condominium to user
 * @param userId: String
 * @param values: {groupId: number, condominiumId: number}
 * @returns {Promise<AxiosResponse<any>>}
 */
const postUserCondominium = ({ userId, values }) => client.post(`/users/${userId}/condominiums`, values);

/**
 * @description Delete one condominium from user
 * @param userId: String
 * @param condominiumId: number
 * @returns {Promise<AxiosResponse<any>>}
 */
const deleteUserCondominium = ({ userId, condominiumId }) => client.delete(
  `/users/${userId}/condominiums/${condominiumId}`,
);

export default {
  putUserCondominium,
  postUserCondominium,
  deleteUserCondominium,
};
