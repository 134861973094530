import client from './client';

/**
 * @description mark message report as processed
 * @param reportId: String
 * @returns {Promise<AxiosResponse<any>>}
 */
const markReportAsProcessed = (reportId) => client.put(`/message-reports/${reportId}/process`);

/**
 * @description mark message report as deleted
 * @param reportId: String
 * @returns {Promise<AxiosResponse<any>>}
 */
const markReportAsDeleted = (reportId) => client.delete(`/message-reports/${reportId}`);

export default {
  markReportAsProcessed,
  markReportAsDeleted,
};
