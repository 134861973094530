import {
  Datagrid,
  ChipField,
  EmailField,
  List,
  SimpleList,
  useTranslate,
  TextInput,
  DateField,
  FunctionField,
  SelectInput,
  useNotify,
  useRefresh,
  Confirm, TextField,
} from 'react-admin';
import { useMediaQuery } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import Tooltip from '@mui/material/Tooltip';
import { useMutation } from 'react-query';
import { useState } from 'react';
// service
import MessagingService from '../../../services/MessagingService';

function MessageReportsList() {
  // local state
  const [confirmationModalVisible, setConfirmationModalVisible] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  // hooks
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const t = useTranslate();
  const notify = useNotify();
  const refresh = useRefresh();

  // queries
  const markAsProcessedMutation = useMutation((id) => MessagingService.markReportAsProcessed(id), {
    onError: () => { notify(t('errors.reportMessage'), { type: 'error' }); },
    onSuccess: () => refresh(),
  });

  const markAsDeletedMutation = useMutation((id) => MessagingService.markReportAsDeleted(id), {
    onError: () => {
      notify(t('errors.reportMessage'), { type: 'error' });
      setConfirmationModalVisible(false);
      setItemToDelete(null);
    },
    onSuccess: () => {
      refresh();
      setConfirmationModalVisible(false);
      setItemToDelete(null);
    },
  });

  // render methods
  const getReportsFilter = () => ([
    <TextInput
      name="author"
      label="labels.searchAuthor"
      source="author"
      alwaysOn
      resettable
      sx={{ margin: '5px 0' }}
    />,
    <SelectInput
      name="status"
      label="labels.searchStatus"
      alwaysOn
      resettable
      source="status"
      choices={[
        { id: 'to_process', name: t('reports.to_process') },
        { id: 'processed', name: t('reports.processed') },
        { id: 'deleted', name: t('reports.deleted') },
      ]}
    />,
  ].filter((filter) => filter !== null)
  );

  const renderAuthorField = (record) => (
    <div>
      <p className="m-0">{`${record?.firstName} ${record?.lastName}`}</p>
      <EmailField source="email" record={record} label="labels.authorEmail" />
    </div>
  );

  const renderStatusField = (record) => {
    const getColor = (status) => {
      switch (status) {
        case 'to_process':
          return 'info';
        case 'processed':
          return 'success';
        case 'deleted':
          return 'error';
        default: {
          return 'info';
        }
      }
    };

    return (
      <ChipField
        source="status"
        record={{ status: t(`reports.${record?.status}`) }}
        color={getColor(record?.status)}
        variant="outlined"
      />
    );
  };

  const renderActions = (record) => (
    <div className="flex">
      <IconButton
        aria-label="delete"
        color="success"
        disabled={record?.status !== 'to_process'}
        onClick={() => markAsProcessedMutation.mutate(record?.id)}
      >
        <Tooltip title={t('reports.validate')} arrow>
          <CheckCircleOutlineIcon />
        </Tooltip>
      </IconButton>
      <IconButton
        onClick={() => {
          setConfirmationModalVisible(true);
          setItemToDelete(record?.id);
        }}
        aria-label="delete"
        color="error"
        disabled={record?.status === 'deleted'}
      >
        <Tooltip title={t('reports.delete')} arrow>
          <HighlightOffIcon />
        </Tooltip>
      </IconButton>
    </div>
  );

  const renderMessageAndAction = (record) => (
    <div>
      <p>{record?.messageContent}</p>
      {renderActions(record)}
    </div>
  );

  return (
    <>
      <List
        exporter={false}
        filters={getReportsFilter()}
        debounce={500}
      >
        {isSmall
          ? (
            <SimpleList
              primaryText={(record) => `${record.firstName} ${record.lastName} ${record.email}`}
              tertiaryText={renderStatusField}
              secondaryText={renderMessageAndAction}
            />
          )
          : (
            <Datagrid rowClick="edit" bulkActionButtons={false}>
              <DateField source="createdAt" label="labels.reportedAt" sortable={false} />
              <FunctionField label="labels.author" render={renderAuthorField} />
              <TextField source="messageContent" label={t('labels.message')} sortable={false} />
              <FunctionField textAlign="center" label="labels.status" render={renderStatusField} />
              <FunctionField render={renderActions} />
            </Datagrid>
          )}
      </List>
      <Confirm
        isOpen={confirmationModalVisible}
        title={t('reports.confirmationModal.title')}
        content={t('reports.confirmationModal.content')}
        onConfirm={() => markAsDeletedMutation.mutate(itemToDelete)}
        onClose={() => {
          setConfirmationModalVisible(false);
          setItemToDelete(null);
        }}
      />
    </>
  );
}

export default MessageReportsList;
