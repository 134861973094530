import {
  Create,
  ListButton,
} from 'react-admin';
import React from 'react';
import { formatEventData } from '../../../utils/formatData';
import EventForm from '../forms/EventForm';

function EventCreate() {
  return (
    <div className="d-flex">
      <ListButton />
      <Create transform={formatEventData}>
        <EventForm />
      </Create>
    </div>
  );
}

export default EventCreate;
