import client from './client';

/**
 * @description get all groups
 * @returns Promise<AxiosResponse<any>>
 */
const getGroups = () => client.get('/groups');

export default {
  getGroups,
};
