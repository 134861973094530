import client from '../client';

const authProvider = {
  // send username and password to the auth server and get back credentials
  login: ({ email, password }) => client.post('/admin/auth', {
    email,
    password,
  }).then(({ data }) => {
    localStorage.setItem('token', data.token);
    localStorage.setItem('refreshToken', data.refresh_token);
  }),
  // remove local credentials and notify the auth server that the user logged out
  logout: () => {
    localStorage.removeItem('token');
    localStorage.removeItem('refreshToken');
    return Promise.resolve();
  },
  // when the dataProvider returns an error, check if this is an authentication error
  checkError: ({ status }) => {
    if (status === 401 || status === 403) {
      return Promise.reject();
    }
    return Promise.resolve();
  },
  // when the user navigates, make sure that their credentials are still valid
  checkAuth: () => client.get('/users/me'),
  // get the user's profile
  getIdentity: () => client.get('/users/me'),
  // get the user permissions (optional)
  getPermissions: () => Promise.resolve(),
};

export default authProvider;
