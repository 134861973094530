const typography = {
  htmlFontSize: 16,
  fontSize: 16,
  fontFamily: "'National', 'Roboto', 'sans-serif'",
  h1: {
    fontFamily: "'National', 'Roboto', 'sans-serif'",
    fontSize: '2rem',
  },
  h2: {
    fontFamily: "'National', 'Roboto', 'sans-serif'",
    fontSize: '1.5rem',
  },
  h3: {
    fontFamily: "'National', 'Roboto', 'sans-serif'",
    fontSize: '1.25rem',
  },
  h4: {
    fontFamily: "'National', 'Roboto', 'sans-serif'",
    fontSize: '1rem',
  },
  h5: {
    fontFamily: "'National', 'Roboto', 'sans-serif'",
    fontSize: '1rem',
  },
  h6: {
    fontFamily: "'National', 'Roboto', 'sans-serif'",
    fontSize: '1rem',
  },
  subtitle1: {
    fontFamily: "'National', 'Roboto', 'sans-serif'",
  },
  subtitle2: {
    fontFamily: "'National', 'Roboto', 'sans-serif'",
  },
  body1: {
    fontFamily: "'National', 'Roboto', 'sans-serif'",
  },
  body2: {
    fontFamily: "'National', 'Roboto', 'sans-serif'",
  },
  button: {
    fontFamily: "'National', 'Roboto', 'sans-serif'",
  },
  caption: {
    fontFamily: "'National', 'Roboto', 'sans-serif'",
  },
};

export default typography;
