import {
  Create,
  ListButton,
} from 'react-admin';
import React from 'react';
import PublicationForm from '../forms/PublicationForm';
import { formatPublicationData } from '../../../utils/formatData';

function PublicationCreate() {
  return (
    <div className="d-flex">
      <ListButton />
      <Create transform={formatPublicationData}>
        <PublicationForm />
      </Create>
    </div>
  );
}

export default PublicationCreate;
