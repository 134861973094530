import {
  ToggleThemeButton, AppBar, useTheme,
} from 'react-admin';
import { useMemo } from 'react';
import { useMediaQuery } from '@mui/material';
import { Link } from 'react-router-dom';
import getTheme from '../../../styles/theme';
import Logo from '../../../assets/images/logo-large.png';
import LogoWhite from '../../../assets/images/logo-large-white.png';
import LogoSmall from '../../../assets/images/logo_small.png';
import LogoSmallWhite from '../../../assets/images/logo_small_white.png';
import CustomUserMenu from './CustomUserMenu';

const appBarContainer = ({ children }) => (
  <div className="fill">
    {children}
  </div>
);

function CustomAppBar() {
  const [theme] = useTheme();
  const isSmall = useMediaQuery((th) => th.breakpoints.down('sm'));

  const logo = useMemo(() => {
    if (isSmall) {
      return (
        {
          source: theme?.palette?.mode === 'dark' ? LogoSmallWhite : LogoSmall,
          width: 70,
        }
      );
    }
    return (
      {
        source: theme?.palette?.mode === 'dark' ? LogoWhite : Logo,
        width: 150,
      }
    );
  }, [theme, isSmall]);

  return (
    <AppBar
      color="transparent"
      container={appBarContainer}
      userMenu={<CustomUserMenu />}
    >
      <div className="flex fill row align-center justify-space-between">
        <Link to="/">
          <img alt="faciliciti-logo" src={logo.source} style={{ width: logo.width }} />
        </Link>
        <ToggleThemeButton
          lightTheme={getTheme('light')}
          darkTheme={getTheme('dark')}
        />
      </div>
    </AppBar>
  );
}

export default CustomAppBar;
