import client from './client';

/**
 * @description mark advert report as processed
 * @param reportId: String
 * @returns {Promise<AxiosResponse<any>>}
 */
const markReportAsProcessed = (reportId) => client.put(`/advert-reports/${reportId}/process`);

/**
 * @description mark advert report as deleted
 * @param advertId: String
 * @returns {Promise<AxiosResponse<any>>}
 */
const markReportAsDeleted = (advertId) => client.delete(`/adverts/${advertId}`);

export default {
  markReportAsProcessed,
  markReportAsDeleted,
};
