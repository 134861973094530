const menu = {
  users: 'Utilisateurs',
  'message-reports': 'Messages signalés',
  'advert-reports': 'Annonces signalées',
  publications: 'Publications',
  events: 'Évènements',
  reports: 'Signalements',
  appointments: 'Passages',
};

export default menu;
