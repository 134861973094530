// ra
import {
  Datagrid,
  ChipField,
  EmailField,
  List,
  SimpleList,
  useTranslate,
  TextInput,
  DateField,
  FunctionField,
  SelectInput,
  useNotify,
  useRefresh,
  Confirm, TextField, UrlField, useRecordContext,
} from 'react-admin';
// mui
import { useMediaQuery } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
// hooks
import { useMutation } from 'react-query';
import { useState } from 'react';
// service
import AdvertService from '../../../services/AdvertService';

function ExpandInfo() {
  // hooks
  const record = useRecordContext();
  const t = useTranslate();

  return (
    <div>
      <Typography
        style={{ fontStyle: 'italic', fontWeight: 100 }}
        variant="body2"
        component="p"
      >
        {t('reports.actualAdvert')}
      </Typography>
      <div className="mt-s">
        <Typography
          style={{ fontStyle: 'italic' }}
          variant="body2"
          component="span"
        >
          {`${t('labels.type')} : `}
        </Typography>
        <Typography
          variant="body2"
          component="span"
        >
          {t(`labels.${record?.advert?.type}`)}
        </Typography>
      </div>
      <div className="mt-s">
        <Typography
          style={{ fontStyle: 'italic' }}
          variant="body2"
          component="span"
        >
          {`${t('labels.description')} : `}
        </Typography>
        <Typography
          variant="body2"
          component="span"
        >
          {record?.advert?.description}
        </Typography>
      </div>
      <div className="mt-s">
        <Typography
          style={{ fontStyle: 'italic' }}
          variant="body2"
          component="span"
        >
          {`${t('labels.picture')} : `}
        </Typography>
        {record?.advert?.pictureFilePath ? (
          <Link target="_blank" rel="noreferrer" href={record?.advert?.pictureFilePath}>
            <Typography
              variant="body2"
              component="span"
            >
              {t('reports.pictureLink')}
            </Typography>
          </Link>
        ) : null}
      </div>
    </div>
  );
}

function MessageReportsList() {
  // local state
  const [confirmationModalVisible, setConfirmationModalVisible] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  // hooks
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const t = useTranslate();
  const notify = useNotify();
  const refresh = useRefresh();

  // queries
  const markAsProcessedMutation = useMutation((id) => AdvertService.markReportAsProcessed(id), {
    onError: () => { notify(t('errors.reportAdvert'), { type: 'error' }); },
    onSuccess: () => refresh(),
  });

  const markAsDeletedMutation = useMutation((id) => AdvertService.markReportAsDeleted(id), {
    onError: () => {
      notify(t('errors.reportAdvert'), { type: 'error' });
      setConfirmationModalVisible(false);
      setItemToDelete(null);
    },
    onSuccess: () => {
      refresh();
      setConfirmationModalVisible(false);
      setItemToDelete(null);
    },
  });

  // render methods
  const getReportsFilter = () => ([
    <TextInput
      name="author"
      label="labels.searchAuthor"
      source="author"
      alwaysOn
      resettable
      sx={{ margin: '5px 0' }}
    />,
    <SelectInput
      name="status"
      label="labels.searchStatus"
      alwaysOn
      resettable
      source="status"
      choices={[
        { id: 'to_process', name: t('reports.to_process') },
        { id: 'processed', name: t('reports.processed') },
        { id: 'deleted', name: t('reports.deleted') },
      ]}
    />,
  ].filter((filter) => filter !== null)
  );

  const renderAuthorField = (record) => (
    <div>
      <p className="m-0">{`${record?.advertAuthorFirstName || '_'} ${record?.advertAuthorLastName || '_'}`}</p>
      <EmailField source="advertAuthorEmail" record={record} label="labels.authorEmail" />
    </div>
  );

  const renderStatusField = (record) => {
    const getColor = (status) => {
      switch (status) {
        case 'to_process':
          return 'info';
        case 'processed':
          return 'success';
        case 'deleted':
          return 'error';
        default: {
          return 'info';
        }
      }
    };

    return (
      <ChipField
        source="status"
        record={{ status: t(`reports.${record?.status}`) }}
        color={getColor(record?.status)}
        variant="outlined"
      />
    );
  };

  const renderActions = (record) => (
    <div className="flex">
      <IconButton
        aria-label="delete"
        color="success"
        disabled={record?.status !== 'to_process'}
        onClick={(e) => {
          e.stopPropagation();
          markAsProcessedMutation.mutate(record?.id);
        }}
      >
        <Tooltip title={t('reports.validate')} arrow>
          <CheckCircleOutlineIcon />
        </Tooltip>
      </IconButton>
      <IconButton
        onClick={(e) => {
          e.stopPropagation();
          setConfirmationModalVisible(true);
          setItemToDelete(record);
        }}
        aria-label="delete"
        color="error"
        disabled={record?.status === 'deleted'}
      >
        <Tooltip title={t('reports.delete')} arrow>
          <HighlightOffIcon />
        </Tooltip>
      </IconButton>
    </div>
  );

  const renderMessageAndAction = (record) => (
    <div>
      <p>{record?.content}</p>
      {renderActions(record)}
    </div>
  );

  return (
    <>
      <List
        exporter={false}
        filters={getReportsFilter()}
        debounce={500}
      >
        {isSmall
          ? (
            <SimpleList
              primaryText={
                (record) => `${
                  record.advertAuthorFirstName || '_'} ${
                  record.advertAuthorLastName || '_'} ${
                  record.advertAuthorEmail || '_'}`
              }
              tertiaryText={renderStatusField}
              secondaryText={renderMessageAndAction}
            />
          )
          : (
            <Datagrid
              rowClick="expand"
              expand={<ExpandInfo />}
              isRowExpandable={(row) => row?.status !== 'deleted'}
              bulkActionButtons={false}
            >
              <DateField source="createdAt" label="labels.reportedAt" sortable={false} />
              <FunctionField label="labels.author" render={renderAuthorField} />
              <TextField source="content" label={t('labels.description')} sortable={false} />
              <UrlField source="picture" label={t('labels.picture')} sortable={false} />
              <FunctionField textAlign="center" label="labels.status" render={renderStatusField} />
              <FunctionField render={renderActions} />
            </Datagrid>
          )}
      </List>
      <Confirm
        isOpen={confirmationModalVisible}
        title={t('reports.advertModal.title')}
        content={t('reports.advertModal.content')}
        onConfirm={() => markAsDeletedMutation.mutate(itemToDelete?.advert?.id)}
        onClose={() => {
          setConfirmationModalVisible(false);
          setItemToDelete(null);
        }}
      />
    </>
  );
}

export default MessageReportsList;
