const news = {
  emptyAddress: 'Dans votre copropriété',
  attendees: 'Participants',
  cancelAction: "Annuler l'évènement",
  cancelActionShort: 'Annuler',
  canceled: 'Évènement annulé',
  cancelModal: {
    title: "Souhaitez-vous annuler l'évènement ?",
    description: "Pour valider l'opération merci de fournir un motif d'annulation",
    cancelReason: "Motif de l'annulation",
    validate: "Confirmer l'annulation",
    cancel: 'Retour',
  },
  cancelSuccess: 'Évènement annulé',
};

export default news;
