import { Layout } from 'react-admin';
import CustomMenu from './CustomMenu';
import CustomAppBar from './CustomAppBar';

function CustomLayout(props) {
  return (
    <Layout
        // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      appBar={CustomAppBar}
      menu={CustomMenu}
      notification={1}
    />
  );
}

export default CustomLayout;
