const errors = {
  resetPassword:
      "Une erreur s'est produite durant la réinitialisation de votre mot de passe.",
  reportMessage: "Une erreur s'est produite lors du traitement du message.",
  reportAdvert: "Une erreur s'est produite lors du traitement du signalement.",
  cancelEvent: "Une erreur est survenue lors de l'annulation de l'évènement",
  ERR_BAD_REQUEST: 'Une erreur est survenue',
  USER_LIST_BAD_REQUEST: 'Une erreur est survenue lors de la récupération des utilisateurs',
  EMAIL_NOT_UNIQUE: 'Un utilisateur avec cet email existe déjà',
  USER_NOT_FOUND: 'Utilisateur introuvable',
  WRONG_DATE_INTERVAL: 'Les dates ne sont pas compatibles entre elles',
  IMPOSSIBLE_TO_LINK_FACILITATOR_WITH_OTHER_GROUP: "Impossible d'associer le rôle facilitateur a cet utilisateur",
  MAIN_FACILITATOR_DELETE_FORBIDDEN: 'Il est impossible de supprimer le facilitateur référent',
};

export default errors;
