import i18n from '../i18n';

/**
 * Check if translation key exist
 * @param key
 * @returns {boolean}
 */
const translationExists = (key) => (
  i18n?.translate(key) !== key
);

/**
 * Format api error message to be translated and displayed
 * @param err: Axios error response
 * @returns {string | undefined}
 */
const formatErrorMessage = (err) => {
  if (err?.response?.status === 400 && err?.response?.data?.errors?.length) {
    return Object.entries(err?.response?.data?.errors).map(([field, message]) => (
      `${i18n?.translate(`labels.${field}`)} : ${i18n?.translate(`errors.${message}`)}`
    ))?.join('\r\n');
  }
  return translationExists(`errors.${err?.response?.data?.message}`)
    ? i18n?.translate(`errors.${err?.response?.data?.message}`) : i18n?.translate('errors.ERR_BAD_REQUEST');
};

export default formatErrorMessage;
