/* eslint-disable react/jsx-props-no-spreading */
import {
  Admin, Resource, CustomRoutes,
} from 'react-admin';
import { QueryClient } from 'react-query';
import { Route } from 'react-router-dom';
import PeopleAltOutlinedIcon from '@mui/icons-material/GroupOutlined';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import EventOutlinedIcon from '@mui/icons-material/EventOutlined';
import DirectionsWalkOutlinedIcon from '@mui/icons-material/DirectionsWalkOutlined';
// Providers
import dataProvider from './services/providers/dataProvider';
import i18nProvider from './services/providers/i18nProvider';
import authProvider from './services/providers/authProvider';
// Components
import UserList from './components/organisms/lists/UserList';
import CustomLayout from './components/organisms/Layout/CustomLayout';
import Dashboard from './components/organisms/Dashboard';
import UserEditPage from './components/organisms/edits/UserEdit';
import UserCreatePage from './components/organisms/create/UserCreate';
import MessageReportsList from './components/organisms/lists/MessageReportsList';
import EventList from './components/organisms/lists/EventList';
import AdvertReportsList from './components/organisms/lists/AdvertReportsList';
import PublicationList from './components/organisms/lists/PublicationList';
import PublicationEdit from './components/organisms/edits/PublicationEdit';
import PublicationCreate from './components/organisms/create/PublicationCreate';
import EventCreate from './components/organisms/create/EventCreate';
import EventEdit from './components/organisms/edits/EventEdit';
import AppointmentList from './components/organisms/lists/AppointmentList';
// Views
import Login from './views/Login';
import ForgotPassword from './views/ForgotPassword';
import ResetPassword from './views/ResetPassword';
// Style
import './styles/global.css';
import theme from './styles/theme';

// React Query Client config
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 1,
      refetchOnWindowFocus: false,
    },
  },
});

function App() {
  return (
    <Admin
      dashboard={Dashboard}
      queryClient={queryClient}
      dataProvider={dataProvider}
      layout={CustomLayout}
      theme={theme('light')}
      i18nProvider={i18nProvider}
      authProvider={authProvider}
      loginPage={Login}
    >
      <Resource
        name="users"
        list={UserList}
        edit={UserEditPage}
        create={UserCreatePage}
        icon={PeopleAltOutlinedIcon}
      />
      <Resource
        name="publications"
        list={PublicationList}
        edit={PublicationEdit}
        create={PublicationCreate}
        icon={ArticleOutlinedIcon}
      />
      <Resource
        name="events"
        list={EventList}
        edit={EventEdit}
        create={EventCreate}
        icon={EventOutlinedIcon}
      />
      <Resource
        name="message-reports"
        list={MessageReportsList}
      />
      <Resource
        name="advert-reports"
        list={AdvertReportsList}
      />
      <Resource
        name="appointments"
        list={AppointmentList}
        icon={DirectionsWalkOutlinedIcon}
      />
      <CustomRoutes noLayout>
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/app-reset-password" element={<ResetPassword />} />
      </CustomRoutes>
    </Admin>
  );
}

export default App;
