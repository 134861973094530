import React, {
  createElement, useCallback, useMemo, useState,
} from 'react';
import {
  Menu, MenuItemLink, useResourceDefinitions, useSidebarState, useTheme, useTranslate,
} from 'react-admin';
// mui
import DefaultIcon from '@mui/icons-material/ViewList';
import { MenuItem } from '@mui/material';
import ReportOutlined from '@mui/icons-material/ReportOutlined';
import IconButton from '@mui/material/IconButton';
import ExpandLessIcon from '@mui/icons-material/ExpandMore';
// style
import style from '../../../styles/components/menu.module.css';

function CustomMenu(props) {
  // local state
  const [isReportMenuOpen, setIsReportMenuOpen] = useState(false);
  // hooks
  const resources = useResourceDefinitions();
  const [open, setOpen] = useSidebarState();
  const t = useTranslate();
  const [theme] = useTheme();

  const itemColor = useMemo(() => (
    theme?.palette?.text
  ), [theme]);

  const primaryColor = useMemo(() => (
    theme?.palette?.primary?.main
  ), [theme]);

  const reportMenuItems = useMemo(
    () => Object.keys(resources)?.filter((name) => name.includes('report')),
    [resources],
  );
  const firstLevelMenuItems = useMemo(
    () => Object.keys(resources)?.filter((name) => !reportMenuItems?.includes(name)),
    [resources, reportMenuItems],
  );

  const isFocused = useCallback((name) => window.location.href?.includes(name), [window.location.href]);

  const getIconStyle = useCallback((name) => {
    const focused = isFocused(name);
    return { opacity: focused ? 1 : 0.6, color: focused ? primaryColor : itemColor };
  }, [primaryColor, itemColor, isFocused]);

  return (
    <Menu
        // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      {firstLevelMenuItems?.map((name) => (
        <MenuItemLink
          key={name}
          to={`/${name}`}
          primaryText={
                  (resources[name].options && resources[name].options.label)
                  || t(`menu.${name}`)
                }
          leftIcon={
                  resources[name].icon
                    ? createElement(resources[name].icon, { style: getIconStyle(name) })
                    : <DefaultIcon sx={getIconStyle(name)} />
                }
        />
      ))}
      {/* Reports Menu and sub menus */}
      <MenuItem
        onClick={() => {
          setIsReportMenuOpen((state) => !state);
          setOpen(true);
        }}
        sx={{ opacity: isFocused('report') ? 1 : 0.6 }}
      >
        <ReportOutlined sx={getIconStyle('report')} />
        <span className={style['item-text']}>{t('menu.reports')}</span>
        <IconButton
          sx={{
            transition: 'all 0.5s ease',
            transform: `rotate(${isReportMenuOpen && open ? 180 : 0}deg)`,
            color: itemColor,
          }}
        >
          <ExpandLessIcon />
        </IconButton>
      </MenuItem>
      <div className={`${style.expandable} ${isReportMenuOpen && open ? style.open : ''}`}>
        {reportMenuItems?.map((name) => (
          <MenuItemLink
            sx={{ fontSize: 16 }}
            key={name}
            to={`/${name}`}
            primaryText={
                    (resources[name].options && resources[name].options.label)
                    || t(`menu.${name}`)
                }
          />
        ))}
      </div>
    </Menu>
  );
}

export default CustomMenu;
