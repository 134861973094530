import PropTypes from 'prop-types';

const condominiumType = PropTypes.shape({
  entitled: PropTypes.string,
  id: PropTypes.number,
});

const groupType = PropTypes.shape({
  code: PropTypes.string,
  id: PropTypes.number,
});

export default {
  condominiumType,
  groupType,
};
