import {
  Edit, ListButton,
} from 'react-admin';
import * as React from 'react';
import UserForm from '../forms/UserForm';
import UserRoleForm from '../forms/userRoleForm';

export function UserEdit() {
  return (
    <div className="d-flex">
      <ListButton />
      <Edit>
        <>
          <UserForm />
          <UserRoleForm />
        </>
      </Edit>
    </div>
  );
}

export default UserEdit;
