import client from './client';

/**
 * @description get all condominiums
 * @returns Promise<AxiosResponse<any>>
 */
const getCondominium = () => client.get('/condominiums');

/**
 * @description make user main facilitator on a specific condominium
 * @param condominiumId
 * @param userId
 * @returns {Promise<AxiosResponse<any>>}
 */
const putMainFacilitator = ({ condominiumId, userId }) => client.put(
  `/condominiums/${condominiumId}/main-facilitator/${userId}`,
);

export default {
  getCondominium,
  putMainFacilitator,
};
